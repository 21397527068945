import useMediaQuery from '@material-ui/core/useMediaQuery';
import ChevronLeftBold from '@pe-libs/react-components/build/components/PeIcons/ChevronLeftBold.svg';
import ChevronRightBold from '@pe-libs/react-components/build/components/PeIcons/ChevronRightBold.svg';
import PeTypography from '@pe-libs/react-components/build/components/PeTypography';
import useIntlMessage from '@pe-libs/react-components/build/lib/intl/hooks/useIntlMessage';
import { useTheme } from '@pe-libs/react-components/build/theme';
import React, { FunctionComponent } from 'react';
import imgArticle1 from '#assets/placeholders/article1.jpg';
import imgArticle2 from '#assets/placeholders/article2.jpg';
import imgArticle3 from '#assets/placeholders/article3.jpg';
import classNames from '#utils/classNames';
import ArticlesSingleBox from './ArticlesSingleBox';
import Carousel from './Carousel';
import messages from './messages';
import useStyles from './styles';

const Article: FunctionComponent = () => {
  const formatMessage = useIntlMessage();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const styles = useStyles();

  const articles = [
    {
      image: imgArticle1,
      title: formatMessage(messages.article1title),
      link: 'https://ratgeber.provenexpert.com/de/was-kostet-ein-steuerberater',
      description: formatMessage(messages.article1desc),
      cta: formatMessage(messages.article1cta),
    },
    {
      image: imgArticle2,
      title: formatMessage(messages.article2title),
      link: 'https://ratgeber.provenexpert.com/de/finden-sie-den-besten-dienstleister-im-expertcompass',
      description: formatMessage(messages.article2desc),
      cta: formatMessage(messages.article2cta),
    },
    {
      image: imgArticle3,
      title: formatMessage(messages.article3title),
      link: 'https://ratgeber.provenexpert.com/de/wie-finde-ich-den-besten-makler-expertcompass',
      description: formatMessage(messages.article3desc),
      cta: formatMessage(messages.article3cta),
    },
  ];

  return (
    <div className={styles.pageContainer}>
      <div className={classNames(styles.articlesSection, styles.center)}>
        <PeTypography variant="h3" classes={{ root: styles.articlesTitle }}>
          {formatMessage(messages.carouselTitle)}
        </PeTypography>
        <div className={styles.articlesCarousel}>
          <Carousel
            beforeSlides={
              !isDesktop && (
                <div className={styles.articlesCarouselNavigation} data-glide-el="controls">
                  <button data-glide-dir="<" type="button" className={classNames(styles.iconButton)}>
                    <ChevronLeftBold />
                  </button>
                  <button data-glide-dir=">" type="button" className={classNames(styles.iconButton)}>
                    <ChevronRightBold />
                  </button>
                </div>
              )
            }
            options={{
              swipeThreshold: isDesktop ? false : 80,
              dragThreshold: isDesktop ? false : 120,
              gap: isSmall ? 8 : 24,
              type: 'carousel',
              perView: 3,
              breakpoints: {
                960: {
                  perView: 1.5,
                },
              },
              rewind: true,
            }}
          >
            {articles.map((article) => (
              <div
                className={classNames(styles.articlesSlide, 'glide__slide')}
                key={article.title}
                title={article.title}
              >
                <ArticlesSingleBox article={article} />
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default Article;
