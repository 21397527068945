import CirclesLinesShare from '@pe-libs/react-components/build/components/PeIcons/CirclesLinesShare.svg';
import PeopleOutlined from '@pe-libs/react-components/build/components/PeIcons/PeopleOutlined.svg';
import StarOutlined from '@pe-libs/react-components/build/components/PeIcons/StarOutlined.svg';
import PeTypography from '@pe-libs/react-components/build/components/PeTypography';
import useIntlMessage from '@pe-libs/react-components/build/lib/intl/hooks/useIntlMessage';
import useIntlNumber from '@pe-libs/react-components/build/lib/intl/hooks/useIntlNumber';
import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { getExternalRatingCrawlerCount } from '#modules/expertDirectory/selectors';
import messages from './messages';
import useStyles from './styles';

const Boxes: FunctionComponent = () => {
  const styles = useStyles();
  const formatMessage = useIntlMessage();
  const formatNumber = useIntlNumber();
  const externalRatingCrawlerCount = useSelector(getExternalRatingCrawlerCount);

  return (
    <div className={styles.boxesSection}>
      <div className={styles.pageContainer}>
        <div className={styles.boxesContainer}>
          <div className={styles.boxesItem}>
            <StarOutlined />
            <PeTypography variant="h5" className={styles.boxesItemTitle}>
              {formatMessage(messages.fact1)}
            </PeTypography>
          </div>
          <div className={styles.boxesItem}>
            <CirclesLinesShare />
            <PeTypography variant="h5" className={styles.boxesItemTitle}>
              {formatMessage(messages.fact2, {
                externalRatingCrawlerCount: formatNumber(externalRatingCrawlerCount, { minimumFractionDigits: 0 }),
              })}
            </PeTypography>
          </div>
          <div className={styles.boxesItem}>
            <PeopleOutlined />
            <PeTypography variant="h5" className={styles.boxesItemTitle}>
              {formatMessage(messages.fact3)}
            </PeTypography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Boxes;
