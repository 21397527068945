import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import PeButtonIcon from '@pe-libs/react-components/build/components/PeButtonIcon';
import ArrowNext from '@pe-libs/react-components/build/components/PeIcons/ArrowNext.svg';
import ArrowPreview from '@pe-libs/react-components/build/components/PeIcons/ArrowPreview.svg';
import Basket from '@pe-libs/react-components/build/components/PeIcons/Basket.svg';
import Camera from '@pe-libs/react-components/build/components/PeIcons/Camera.svg';
import Car from '@pe-libs/react-components/build/components/PeIcons/Car.svg';
import ChatBubblesOutlined from '@pe-libs/react-components/build/components/PeIcons/ChatBubblesOutlined.svg';
import CheckShield from '@pe-libs/react-components/build/components/PeIcons/CheckShield.svg';
import Craftsmen from '@pe-libs/react-components/build/components/PeIcons/Craftsmen.svg';
import EqualScale from '@pe-libs/react-components/build/components/PeIcons/EqualScale.svg';
import EuroDocument from '@pe-libs/react-components/build/components/PeIcons/EuroDocument.svg';
import GearMonitor from '@pe-libs/react-components/build/components/PeIcons/GearMonitor.svg';
import GrowingBarChart from '@pe-libs/react-components/build/components/PeIcons/GrowingBarChart.svg';
import Handshake from '@pe-libs/react-components/build/components/PeIcons/Handshake.svg';
import Home from '@pe-libs/react-components/build/components/PeIcons/Home.svg';
import Keynote from '@pe-libs/react-components/build/components/PeIcons/Keynote.svg';
import MagicWand from '@pe-libs/react-components/build/components/PeIcons/MagicWand.svg';
import MedicalCase from '@pe-libs/react-components/build/components/PeIcons/MedicalCase.svg';
import Megaphone from '@pe-libs/react-components/build/components/PeIcons/Megaphone.svg';
import MegaphoneMonitor from '@pe-libs/react-components/build/components/PeIcons/MegaphoneMonitor.svg';
import PeopleOutlined from '@pe-libs/react-components/build/components/PeIcons/PeopleOutlined.svg';
import Tool from '@pe-libs/react-components/build/components/PeIcons/Tool.svg';
import Volleyball from '@pe-libs/react-components/build/components/PeIcons/Volleyball.svg';
import PeLink from '@pe-libs/react-components/build/components/PeLink';
import PeTypography from '@pe-libs/react-components/build/components/PeTypography';
import useIntlMessage from '@pe-libs/react-components/build/lib/intl/hooks/useIntlMessage';
import useIntlNumber from '@pe-libs/react-components/build/lib/intl/hooks/useIntlNumber';
import { useTheme } from '@pe-libs/react-components/build/theme';
import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { getSearchUrl } from '#helpers/searchUrl';
import { getUserLocale } from '#modules/app/selectors';
import { getGlobalSystemInfo } from '#modules/app/selectors/globalSystemInfo';
import Carousel from './Carousel';
import CategorySingleBox from './CategorySingleBox';
import { createSearchUrl } from './helper';
import messages from './messages';
import useStyles from './styles';

const Categories: FunctionComponent = () => {
  const styles = useStyles();
  const formatMessage = useIntlMessage();
  const formatNumber = useIntlNumber();
  const locale = useSelector(getUserLocale)?.toLocaleLowerCase() ?? 'de-de';
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));

  const categories = [
    {
      link: createSearchUrl(locale, 'Finanzdienstleistungen'),
      icon: <EuroDocument />,
      title: formatMessage(messages.categoryFinancialServices),
    },
    {
      link: createSearchUrl(locale, 'Versicherungsdienstleistungen'),
      icon: <CheckShield />,
      title: formatMessage(messages.categoryInsuranceServices),
    },
    {
      link: createSearchUrl(locale, 'Immobilienvermittlung'),
      icon: <Home />,
      title: formatMessage(messages.categoryRealEstate),
    },
    {
      link: createSearchUrl(locale, 'Rechtsdienstleistungen'),
      icon: <EqualScale />,
      title: formatMessage(messages.categoryLegalServices),
    },
    {
      link: createSearchUrl(locale, 'Autohandel'),
      icon: <Car />,
      title: formatMessage(messages.categoryCarSales),
    },
    {
      link: createSearchUrl(locale, 'Marketing'),
      icon: <Megaphone />,
      title: formatMessage(messages.categoryMarketing),
    },
    {
      link: createSearchUrl(locale, 'Online Marketing'),
      icon: <MegaphoneMonitor />,
      title: formatMessage(messages.categoryOnlineMarketing),
    },
    {
      link: createSearchUrl(locale, 'E-Commerce'),
      icon: <Basket />,
      title: formatMessage(messages.categoryECommerce),
    },
    {
      link: createSearchUrl(locale, 'Coaching'),
      icon: <GearMonitor />,
      title: formatMessage(messages.categoryCoaching),
    },
    {
      link: createSearchUrl(locale, 'IT-Dienstleistungen'),
      icon: <PeopleOutlined />,
      title: formatMessage(messages.categoryItServices),
    },
    {
      link: createSearchUrl(locale, 'Beratung'),
      icon: <ChatBubblesOutlined />,
      title: formatMessage(messages.categoryConsulting),
    },
    {
      link: createSearchUrl(locale, 'Unternehmensberatung'),
      icon: <GrowingBarChart />,
      title: formatMessage(messages.categoryBusinessConsulting),
    },
    {
      link: createSearchUrl(locale, 'Events & Entertainment'),
      icon: <Keynote />,
      title: formatMessage(messages.categoryEventsAndEntertainment),
    },
    {
      link: createSearchUrl(locale, 'Fotografie'),
      icon: <Camera />,
      title: formatMessage(messages.categoryPhotography),
    },
    {
      link: createSearchUrl(locale, 'Mediengestaltung'),
      icon: <MagicWand />,
      title: formatMessage(messages.categoryMediaDesign),
    },
    {
      link: createSearchUrl(locale, 'Handwerk'),
      icon: <Tool />,
      title: formatMessage(messages.categoryHandicraft),
    },
    {
      link: createSearchUrl(locale, 'Bauwesen'),
      icon: <Craftsmen />,
      title: formatMessage(messages.categoryConstruction),
    },
    {
      link: createSearchUrl(locale, 'Ärzte & Heilpraktiker'),
      icon: <MedicalCase />,
      title: formatMessage(messages.categoryDoctorsAndNaturopaths),
    },
    {
      link: createSearchUrl(locale, 'Training'),
      icon: <Volleyball />,
      title: formatMessage(messages.categoryTraining),
    },
    {
      link: createSearchUrl(locale, 'Dienstleistungen'),
      icon: <Handshake />,
      title: formatMessage(messages.categoryServices),
    },
  ];

  const {
    attributes: { industryTotalCount },
  } = useSelector(getGlobalSystemInfo);

  return (
    <div className={styles.pageContainer}>
      <div className={styles.categoriesSection}>
        <PeTypography variant="h4" className={styles.categoriesTitle}>
          {formatMessage(messages.sectionCategoriesTitle, {
            link: (
              <PeLink
                title={formatMessage(messages.hoverCategoriesLink)}
                href={`/${locale}/${getSearchUrl(locale)}/branchen/`}
                className={styles.redirectionLink}
              >
                {formatMessage(messages.sectionCategoriesLink, {
                  categoriesTotal: formatNumber(industryTotalCount as number, { minimumFractionDigits: 0 }),
                })}
              </PeLink>
            ),
          })}
        </PeTypography>
        <Grid item={true} xs={12} md={10} className={styles.categoriesCarouselContainer}>
          <div className={styles.categoriesCarousel}>
            <Carousel
              beforeSlides={
                <div data-glide-el="controls" className={styles.leftArrowContainer}>
                  <PeButtonIcon
                    data-glide-dir="<"
                    color="primary"
                    variant="contained"
                    size="small"
                    className={styles.categoriesSliderArrow}
                    aria-label="go to previous slide button"
                  >
                    <ArrowPreview />
                  </PeButtonIcon>
                </div>
              }
              afterSlides={
                <div data-glide-el="controls" className={styles.rightArrowContainer}>
                  <PeButtonIcon
                    color="primary"
                    variant="contained"
                    size="small"
                    data-glide-dir=">"
                    className={styles.categoriesSliderArrow}
                    aria-label="go to next slide button"
                  >
                    <ArrowNext />
                  </PeButtonIcon>
                </div>
              }
              options={{
                swipeThreshold: isDesktop ? false : 80,
                dragThreshold: isDesktop ? false : 120,
                gap: isExtraSmall ? 8 : 16,
                type: 'carousel',
                perView: 5,
                breakpoints: {
                  1120: {
                    perView: 4,
                  },
                  820: {
                    perView: 3,
                  },
                  460: {
                    perView: 2.5,
                  },
                  320: {
                    perView: 2,
                  },
                },
                rewind: true,
              }}
              classes={{ track: styles.track }}
            >
              {categories.map((category) => (
                <CategorySingleBox category={category} key={category.title} />
              ))}
            </Carousel>
          </div>
        </Grid>
      </div>
    </div>
  );
};

export default Categories;
