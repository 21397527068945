import useImage from '@pe-libs/react-components/build/lib/hooks/useImage';
import React, { FC } from 'react';
import imageDesktop from '#assets/images/expertDirectory/searchDesktop.jpg';
import imageMobile from '#assets/images/expertDirectory/searchMobile.jpg';
import imageTablet from '#assets/images/expertDirectory/searchTablet.jpg';
import Footer from '#components/Footer';
import BottomSearchSection from '../BottomSearchSection';
import SearchSection from '../SearchSection';
import Article from './Article';
import Articles from './Articles';
import Boxes from './Boxes';
import Categories from './Categories';
import useStyles from './styles';

const Landing: FC = () => {
  const styles = useStyles();
  const backgroundImg = useImage(imageMobile, imageTablet, imageDesktop);

  return (
    <>
      <SearchSection hasSearchBar={true} className={styles.searchSection} bgImage={backgroundImg} />
      <Categories />
      <Article />
      <Boxes />
      <Articles />
      <BottomSearchSection />
      <Footer />
    </>
  );
};

export default Landing;
