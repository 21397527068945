import PeCard from '@pe-libs/react-components/build/components/PeCard';
import PeLink from '@pe-libs/react-components/build/components/PeLink';
import React, { FC, forwardRef, LegacyRef } from 'react';
import classNames from '#utils/classNames';
import useStyles from './styles';
import { CategoriesItemsProps } from './types';

type CategorySingleBoxProps = {
  category: CategoriesItemsProps;
  className?: string;
};

const CategorySingleBox: FC<CategorySingleBoxProps> = forwardRef(
  ({ category }, reference: LegacyRef<HTMLDivElement> | undefined) => {
    const styles = useStyles();

    return (
      <div className={classNames('glide__slide', styles.categoryWrapper)} ref={reference}>
        <PeCard className={classNames(styles.category)}>
          <PeLink href={`${category.link}`} className={styles.categoryLink}>
            <div className={styles.categoryIcon}>{category.icon}</div>
            <div>
              <span className={styles.categoryTitle}>{category.title}</span>
            </div>
          </PeLink>
        </PeCard>
      </div>
    );
  },
);

CategorySingleBox.displayName = 'CategorySingleBox';

export default CategorySingleBox;
