// eslint-disable max-len

import Glide, { Options } from '@glidejs/glide';
import React, { useImperativeHandle, useEffect, useRef, FC } from 'react';
import classNames from '#utils/classNames';

type Props = {
  options: Partial<Options>;
  beforeSlides?: false | JSX.Element;
  afterSlides?: false | JSX.Element;
  classes?: { track?: string; glide?: string };
};

const Carousel: FC<Props> = React.forwardRef(({ options, children, beforeSlides, afterSlides, classes }, reference) => {
  const sliderReference = useRef<HTMLDivElement>(null);

  useImperativeHandle(reference, () => sliderReference.current);

  useEffect(() => {
    const slider = new Glide((sliderReference.current as unknown) as string, options).mount();

    return () => {
      slider.destroy();
    };
  }, [options]);

  return (
    <div className={classNames('glide', classes?.glide ?? '')} ref={sliderReference}>
      {beforeSlides}
      <div data-glide-el="track" className={classNames(classes?.track ?? '', 'glide__track')}>
        <div className="glide__slides">{children}</div>
      </div>
      {afterSlides}
    </div>
  );
});

Carousel.displayName = 'Carousel';

export default Carousel;
