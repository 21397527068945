import PeButton from '@pe-libs/react-components/build/components/PeButton';
import PeCard from '@pe-libs/react-components/build/components/PeCard';
import PeLink from '@pe-libs/react-components/build/components/PeLink';
import PeTypography from '@pe-libs/react-components/build/components/PeTypography';
import useIntlMessage from '@pe-libs/react-components/build/lib/intl/hooks/useIntlMessage';
import React, { FC, forwardRef, LegacyRef } from 'react';
import { articleSchema } from '#modules/expertDirectory/schemas';
import yup from '#schema/locale';
import classNames from '#utils/classNames';
import messages from './messages';
import useStyles from './styles';

type ArticlesSingleBoxProps = {
  article: yup.InferType<typeof articleSchema>;
  className?: string;
};

const ArticlesSingleBox: FC<ArticlesSingleBoxProps> = forwardRef(
  ({ article, className }, reference: LegacyRef<HTMLDivElement> | undefined) => {
    const styles = useStyles();
    const formatMessage = useIntlMessage();

    return (
      <div className="glide__slide" ref={reference}>
        <PeCard className={classNames(styles.singleBoxContainer, className ?? '')}>
          <PeLink title={article.title} target="_blank" href={article.link}>
            <div className={styles.singleBoxImageContainer}>
              <div className={styles.singleBoxImage} style={{ backgroundImage: `url(${article.image})` }}>
                <div className={styles.singleBoxImageFade} />
              </div>
            </div>
          </PeLink>
          <div className={styles.singleBoxContent}>
            <PeLink title={article.title} target="_blank" href={article.link} className={styles.singleBoxTitleLink}>
              <PeTypography variant="body1" classes={{ root: styles.singleBoxTitle }} gutterBottom={true}>
                {article.title}
              </PeTypography>
            </PeLink>
            <PeTypography variant="body1" className={styles.singleBoxDesc}>
              {article.description}
            </PeTypography>
            <PeLink href={article.link} target="_blank" className={styles.singleBoxButton}>
              <PeButton
                color="primary"
                variant="outlined"
                fullWidth={true}
                title={formatMessage(messages.hoverReadMore)}
                role="button"
                aria-label={`${article.cta} button`}
              >
                {article.cta}
              </PeButton>
            </PeLink>
          </div>
        </PeCard>
      </div>
    );
  },
);

ArticlesSingleBox.displayName = 'ArticlesSingleBox';

export default ArticlesSingleBox;
